import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {AuthService} from '../../auth/services/Auth/auth.service';

@Injectable()
export class SheetsService {
  private $auth = new BehaviorSubject(null);

  constructor(
    private authService: AuthService
  ) {}

  async authCheck() {
    if (this.$auth.value) {
      this.$auth.next(this.$auth.value);
      return this.$auth.value;
    } else {
      const scopes = ['https://www.googleapis.com/auth/drive.file'];
      let response;

      try {
        response = await this.authService.getAuthForScopes(scopes);
        this.$auth.next(response);
        return response;
      } catch (e) {
        this.$auth.next(false);
        throw e;
      }
    }
  }
}
