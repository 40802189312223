
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { AuthService } from '../Auth/auth.service';

@Injectable()
export class ApiRequestInterceptorService implements HttpInterceptor {
  protected serviceUrls = environment.urls.userServices;

  constructor(public auth: AuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.auth.getCurrentUser()) {
      if (this.canMakeCallWithoutLoggedInUser(request)) {
        return next.handle(request).pipe(catchError(caughtError => this.handleAuthError(caughtError)));
      }

      const error = new HttpErrorResponse({
        error: 'No current user',
        headers: request.headers,
        status: 403,
        statusText: 'No current user',
        url: request.url || undefined
      });

      return this.handleAuthError(error);
    }

    return from(this.auth.getCurrentUserIdToken()).pipe(switchMap(fbIdToken => {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getCurrentUser().auth.jwt}`,
          'x-cdw-app-name': 'lsc',
          'x-cdw-fbauth-token': `Bearer ${fbIdToken}`,
        },
      });
      return next.handle(request);
    })).pipe(catchError(caughtError => this.handleAuthError(caughtError)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // console.log('handleAuthError', err);
    if (err.status === 401 || err.status === 403) {
      this.auth.signOut(false);
      return of(err.message);
    }
    return throwError(err);
  }

  private canMakeCallWithoutLoggedInUser(request: HttpRequest<any>): boolean {
    if (request.url === this.serviceUrls.audits.route) {
      return true;
    }
    if (request.url === this.serviceUrls.audits.create) {
      return true;
    }
    return false;
  }
}




