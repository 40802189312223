import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import firebase from 'firebase/compat/app';
import {
  BehaviorSubject,
  Observable,
  of,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { FirebaseAnalyticsService } from '../core/services/FirebaseAnalytics/firebase-analytics.service';
import { User } from '../modules/auth/services/Auth/auth.service';
import { AuditsService } from './Audits/audits.service';
import { UserListResponse } from './user.service';

export interface CollectionOwner {
  gId: string;
  schoolIds: string[];
  displayName: string;
  email: string;
  thumbnail: string;
}

export interface StudentCollection {
  name: string;
  description?: string;
  created?: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  updated: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  members: string[] | firebase.firestore.FieldValue;
}

export interface StudentCollectionSyncState {
  message?: string;
  active?: boolean;
  batchComplete?: number;
  batchCount?: number;
  start?: firebase.firestore.Timestamp;
  end?: firebase.firestore.Timestamp;
  lastActive?: firebase.firestore.Timestamp;
}

export interface RefreshStudentCollectionResponse {
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CollectionService {

  readonly maxCollectionsPerUser: number = 12;
  readonly maxUsersPerCollection: number = 250;
  readonly maxCollectionWarning: string = 'You have reached the maximum number of Collections a user can have, but you can add students to an existing Collection.';
  private customerCollectionsRef$: AngularFirestoreCollection<CollectionOwner>;
  private customerId: string;
  private activeCollection$: Observable<StudentCollection>;
  private activeCollectionSyncState$: Observable<StudentCollectionSyncState>;
  private user: User;
  private serviceUrls = environment.urls.userServices;
  private httpOptions: { headers: HttpHeaders };
  public readonly myCollectionCardColor = '#B0C4DE';
  private readonly cardColors = [
    '#03e5bf',
    '#b19df9',
    '#e985c6',
    '#ffcb80',
    '#a8f5ad',
    '#fa9799',
    '#f2faa0',
    '#99a6fa',
    '#fa99d6',
  ];

  public selectedCollections = [];
  public collectionsSelected$ = new BehaviorSubject<boolean>(false).pipe(switchMap(selectValues => {
    const selectedCollections = Object.entries(selectValues).map( ([collectionOwnerId, collectionObjs]) =>
          Object.entries(collectionObjs).reduce((selectedCollections, [collectionId, collectionObj]: [any,any]) => {
           collectionObj.ownerId = collectionOwnerId;
           if(collectionObj.selected){
              collectionObj.collectionId = collectionId;
              selectedCollections.push(collectionObj);
            }
           return selectedCollections;
         }, [])
      // @ts-ignore
    ).flat(); // ts throws error though this is valid
    this.selectedCollections = selectedCollections;
    return of(selectedCollections.length > 0);
  }));

  callableCF: {
    refreshCollection: any;
  };

  constructor(private afs: AngularFirestore,
              private fns: AngularFireFunctions,
              private http: HttpClient,
              private _firebaseAnalytics: FirebaseAnalyticsService,
              private auditService: AuditsService
              ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.callableCF = {
      refreshCollection: fns.httpsCallable('collections-refreshStudentCollection'),
    };
  }

  loadOtherCollectionsForUser(user: User): Observable<DocumentChangeAction<CollectionOwner>[]> {
    this.customerId = user.gSuiteId;
    this.user = user;
    return this.customerCollectionsRef$ ? this.customerCollectionsRef$.snapshotChanges() : this.setCustomerCollectionsRef(user).snapshotChanges();
  }

  getCollectionOwner(user: User) {
    return this.afs.collection('customers').doc(user.gSuiteId).collection('collections').doc<CollectionOwner>(user.profile.gId).snapshotChanges();
  }

  loadForUser(user: User): Observable<DocumentChangeAction<StudentCollection>[]> {
    this.user = user;
    if (!this.customerCollectionsRef$) {
      this.loadOtherCollectionsForUser(user);
    }
    return this.afs.collection('customers').doc(this.user.gSuiteId).collection('collections').doc(this.user.profile.gId).collection('student_collections').snapshotChanges() as Observable<DocumentChangeAction<StudentCollection>[]>;
  }


  async deleteCollections(collections){
    const auditEntryInfo = {
      typeId: '20',
      customer_id: this.user.profile.customerId,
      user_id: this.user.profile.id,
      data: {
        collections: collections.map(collection => ({
          ownerId: collection.ownerId,
          name: collection.collectionData.name,
        })),
      },
    };
    try {
      const deleteBatch = this.afs.firestore.batch();
      let ownerRef$;
      collections.forEach(collection => {
        if (!ownerRef$) {
          ownerRef$ = this.customerCollectionsRef$.doc(collection.ownerId);
        }
        deleteBatch.delete(ownerRef$.collection('student_collections').doc(collection.collectionId).ref);

        const analyticsEvent = {
          action: 'Collection deleted',
          properties: {
            customerId: this.user.gSuiteId,
          },
        };
        this._firebaseAnalytics.sendEvent(analyticsEvent);
      });

      await deleteBatch.commit();
      await this.auditService.createAuditLog({ ...auditEntryInfo, actionId: '131' }).toPromise();

      if (ownerRef$) {
        // Prune owner document if no collections for this owner
        ownerRef$.collection('student_collections').get().subscribe((results) => {
          if (!results.docs || !results.docs.length) {
            ownerRef$.delete();
          }
        });
      }
    } catch (e) {
      await this.auditService.createAuditLog({ ...auditEntryInfo, actionId: '192' }).toPromise();
      console.error('Error deleting collections', e);
    }
  }

  async saveCollection(partialCollection: any, collectionId?: string): Promise<void> {
    if (!this.user) {
      throw new Error('Cannot save - missing user info');
    }
    if (collectionId) {
      partialCollection.updated = firebase.firestore.FieldValue.serverTimestamp();
    } else {
      partialCollection.created = firebase.firestore.FieldValue.serverTimestamp();
      partialCollection.updated = firebase.firestore.FieldValue.serverTimestamp();
      partialCollection.customerId =  this.user.gSuiteId;
    }

    let newMemberCount = 0;
    let newMemberIds = [];

    if (partialCollection.members) {
      newMemberCount = partialCollection.members.length;
      newMemberIds = partialCollection.members;
      partialCollection.members = firebase.firestore.FieldValue.arrayUnion(...partialCollection.members);
    }

    // Update collection owner doc
    // TODO: James this write should be either debounce or diff/checked to avoid unnecessary writes
    const collectionOwnerDoc$ = this.customerCollectionsRef$.doc(this.user.profile.gId);
    const docData: any = {
      displayName: this.user.profile.displayName,
      email: this.user.profile.primaryEmail,
      gId: this.user.profile.gId,
    };
    if (this.user.profile.photoUrl) {
      docData.thumbnail = this.user.profile.photoUrl;
    }
    await collectionOwnerDoc$.set(docData, {merge: true});
    const userCollectionRef$ = collectionOwnerDoc$.collection('student_collections');
    const collectionDoc = collectionId ? userCollectionRef$.doc<StudentCollection>(collectionId) : userCollectionRef$.doc<StudentCollection>();
    this.loadActiveCollectionSyncState_(collectionDoc);
    await collectionDoc.set(partialCollection, {merge: true});

    const newCollectionAuditEntryInfo = {
      typeId: '20',
      customer_id: this.user.profile.customerId,
      user_id: this.user.profile.id,
      data: {
        name: partialCollection.name,
      },
    };
    try {
      if(!collectionId){
        const analyticsEvent = {
          action: 'Collection create',
          properties: {
            customerId: this.user.gSuiteId,
          },
        };
        this._firebaseAnalytics.sendEvent(analyticsEvent);

        await this.auditService.createAuditLog({ ...newCollectionAuditEntryInfo, actionId: '130' }).toPromise();
      }
    } catch (error) {
      await this.auditService.createAuditLog({ ...newCollectionAuditEntryInfo, actionId: '193' }).toPromise();
    }

    const newMembersAuditEntryInfo = {
      typeId: '20',
      customer_id: this.user.profile.customerId,
      user_id: this.user.profile.id,
      data: {
        collectionName: partialCollection.name,
        collectionId:  collectionDoc.ref.id,
        students: newMemberIds,
      },
    };
    try {
      const addMemberAnalytics = {
        action: 'Collection add users',
        properties: {
          customerId: this.customerId,
          count: newMemberCount,
        },
      };
      this._firebaseAnalytics.sendEvent(addMemberAnalytics);

      await this.auditService.createAuditLog({ ...newMembersAuditEntryInfo, actionId: '132' }).toPromise();
    } catch (error) {
      await this.auditService.createAuditLog({ ...newMembersAuditEntryInfo, actionId: '194' }).toPromise();
    }
  }

  loadStudentCollectionsByUserId(id: string, user: User): Observable<DocumentChangeAction<StudentCollection>[]> {
    if (this.customerCollectionsRef$) {
      return this.customerCollectionsRef$.doc(id).collection<StudentCollection>('student_collections').snapshotChanges();
    } else {
      return this.setCustomerCollectionsRef(user).doc(id).collection<StudentCollection>('student_collections').snapshotChanges();
    }
  }

  setActiveCollection(customerId: string, userId: string, collectionId: string): Observable<StudentCollection> {
    const activeCollectionRef = this.afs.collection('customers').doc(customerId).collection('collections').doc(userId).collection('student_collections').doc<StudentCollection>(collectionId);
    this.activeCollection$ = activeCollectionRef.valueChanges();
    this.loadActiveCollectionSyncState_(activeCollectionRef);
    return this.activeCollection$;
  }

  getActiveCollectionSyncState(): Observable<StudentCollectionSyncState> {
    return this.activeCollectionSyncState$;
  }

  getSummaryRows(body): Observable<UserListResponse> {

    const url = this.serviceUrls.studentCollections.getSummaryRows;
    return this.http.post<UserListResponse>(url, body, this.httpOptions);
  }

  getCardColor(index?: number): string {
    const color = index !== undefined ? this.hexToRGB_(this.cardColors[index % this.cardColors.length], 0.2) : this.hexToRGB_(this.myCollectionCardColor);
    return color;
  }

  setActiveUser(user: User) {
    this.user = user;
    if(user) {
      this.setCustomerCollectionsRef(user);
    }
  }

  async removeStudentsFromCollection(userIds: string[], collectionId: string, collectionName: string): Promise<void> {
    if (!this.user) {
      throw new Error('Cannot save - missing user info');
    }

    const collectionDoc = this.customerCollectionsRef$.doc(this.user.profile.gId).collection('student_collections').doc(collectionId);
    await collectionDoc.set({
      members: firebase.firestore.FieldValue.arrayRemove(...userIds),
    }, {merge: true});

    const auditEntryInfo = {
      typeId: '20',
      customer_id: this.user.profile.customerId,
      user_id: this.user.profile.id,
      data: {
        collectionName,
        collectionId:  collectionDoc.ref.id,
        students: userIds,
      },
    };
    try {
      const analyticsEvent = {
        action: 'Collection remove users',
        properties: {
          customerId: this.customerId,
          count: userIds.length,
        },
      };
      this._firebaseAnalytics.sendEvent(analyticsEvent);

      await this.auditService.createAuditLog({ ...auditEntryInfo, actionId: '133' }).toPromise();
    } catch (error) {
      await this.auditService.createAuditLog({ ...auditEntryInfo, actionId: '195' }).toPromise();
    }
  }

  refreshCollection(collectionId: string, memberIds: string[]): Observable<RefreshStudentCollectionResponse> {
    const data = {
      customerId: this.user.gSuiteId,
      userId: this.user.profile.gId,
      collectionId,
      memberIds,
    };
    return this.callableCF.refreshCollection(data);

  }

  private hexToRGB_(hex: string, alpha?: number): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  private setCustomerCollectionsRef(user: User): AngularFirestoreCollection<CollectionOwner> {
    this.customerId = user.gSuiteId;
    // TODO: Handle paging for large numbers of users who have created collections
    this.customerCollectionsRef$ = this.afs.collection('customers').doc(this.customerId).collection('collections');
    return this.customerCollectionsRef$;
  }

  private compactAGFilter_(filterObj, field) {

    return {
      c: field,
      f: filterObj,
    };

  }

  private loadActiveCollectionSyncState_(activeCollectionRef: AngularFirestoreDocument<StudentCollection>): void {
    this.activeCollectionSyncState$ = activeCollectionRef.collection('jobs').snapshotChanges().pipe(
      switchMap((snapshots) => {
        for (const snap of snapshots) {
          try {
            return this.afs.doc(snap.payload.doc.ref.path).collection('meta').doc('state').valueChanges();
          } catch(e) {
            console.warn((e as Error).message);
          }
        }
        return of(null);
      })
    );
  }
}
